import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Grid, Typography as MuiTypography } from "@mui/material";
import { spacing } from "@mui/system";

const Typography = styled(MuiTypography)(spacing);

function DevBoard() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title="Developer Board" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h1" gutterBottom>
            Developer Board
          </Typography>
          <Typography variant="h3">{t("Nothing here yet...")}</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DevBoard;
