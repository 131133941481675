import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

const AuthText = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div>
      <Typography variant="h6" color={theme.palette.lemon.main}>
        {t("harborConnect")}
      </Typography>
      <Typography
        fontFamily="Hind"
        fontWeight={700}
        fontSize={48}
        lineHeight="50px"
        marginTop="17px"
        marginBottom="37px"
        color="white"
      >
        {t("welcomeMessageTitle")}
      </Typography>
      <Typography
        fontFamily="Hind"
        fontWeight={700}
        fontSize={22}
        lineHeight="26px"
        marginBottom="17px"
        color="white"
      >
        {t("welcomeMessageDescription")}
      </Typography>
    </div>
  );
};

export default AuthText;
