import React from "react";
import { Tower } from "../../base/models/Tower";
import { TowerStatus } from "../../base/models/TowerStatus";
import { LockersTable } from "./lockersTable";
import { CardContent, Grid } from "@mui/material";
import { Card, TextField, Divider } from "../../theme/styledComponents";

export function Details({ tower }: { tower: Tower }) {
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container spacing={6}>
          <Grid item md={6}>
            <TextField
              id="id"
              label="ID"
              variant="outlined"
              defaultValue={tower.id}
              fullWidth
              my={2}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              id="location"
              label="Location"
              variant="outlined"
              defaultValue={tower.locationId}
              fullWidth
              my={2}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item md={6}>
            <TextField
              id="name"
              label="Tower Name"
              variant="outlined"
              defaultValue={tower.name}
              fullWidth
              my={2}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              id="status"
              label="Status"
              variant="outlined"
              defaultValue={TowerStatus[tower.statusId]}
              fullWidth
              my={2}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item md={6}>
            <TextField
              id="system"
              label="System"
              variant="outlined"
              fullWidth
              defaultValue={tower.systemId}
              my={2}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              id="model"
              label="Tower Model"
              variant="outlined"
              fullWidth
              defaultValue={tower.towerModelId}
              my={2}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        <Divider mt={10} />
        <LockersTable tower={tower} lockers={tower.lockers} />
      </CardContent>
    </Card>
  );
}
