/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { useContext } from "react";
import { AuthContext } from "../../contexts/KeycloakContextProvider";
import type { Locker } from "../models/Locker";
import type { Status } from "../models/Status";
import type { Tower } from "../models/Tower";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class TowersService {
  static setRequestToken(token: string | undefined) {
    OpenAPI.TOKEN = token;
  }
  /**
   * Get All Towers
   * @returns Tower Successful Response
   * @throws ApiError
   */
  public static getAllTowersApiV1TowersGet(): CancelablePromise<Array<Tower>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/v1/towers/",
    });
  }

  /**
   * Get Tower
   * @param towerId
   * @returns Tower Successful Response
   * @throws ApiError
   */
  public static getTowerApiV1TowersTowerIdGet(
    towerId: string
  ): CancelablePromise<Tower> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/v1/towers/{tower_id}",
      path: {
        tower_id: towerId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Reset Tower Events
   * @param towerId
   * @returns Tower Successful Response
   * @throws ApiError
   */
  public static resetTowerEventsApiV1TowersTowerIdResetEventsPost(
    towerId: string
  ): CancelablePromise<Tower> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/v1/towers/{tower_id}/reset-events",
      path: {
        tower_id: towerId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Lockers
   * @param towerId
   * @returns Locker Successful Response
   * @throws ApiError
   */
  public static getLockersApiV1TowersTowerIdLockersGet(
    towerId: string
  ): CancelablePromise<Array<Locker>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/v1/towers/{tower_id}/lockers",
      path: {
        tower_id: towerId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Set Lockers To Available
   * @param towerId
   * @returns Locker Successful Response
   * @throws ApiError
   */
  public static setLockersToAvailableApiV1TowersTowerIdReleaseLockersPost(
    towerId: string
  ): CancelablePromise<Array<Locker>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/v1/towers/{tower_id}/release-lockers",
      path: {
        tower_id: towerId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Locker Status
   * @param lockerId
   * @param lockerStatus
   * @returns Locker Successful Response
   * @throws ApiError
   */
  public static updateLockerStatusApiV1TowersTowerIdLockersLockerIdPut(
    lockerId: number,
    lockerStatus: Status
  ): CancelablePromise<Locker> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/v1/towers/{tower_id}/lockers/{locker_id}",
      path: {
        locker_id: lockerId,
      },
      query: {
        locker_status: lockerStatus,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
