/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum TowerStatus {
  "AVAILABLE" = 1,
  "OUT_OF_SERVICE" = 2,
  "PENDING" = 3,
  "RETIRED" = 4,
}
