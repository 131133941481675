import React, { ReactNode } from "react";
import Notify from "../components/Notify";
import Keycloak, { KeycloakInitOptions } from "keycloak-js";
import { createContext, useEffect, useState } from "react";
import { useNotify } from "../hooks/useNotify";
const MINVALIDITY = 5;

const keycloakInitOptions: KeycloakInitOptions = {
  pkceMethod: "S256",
};

// @ts-ignore
const keycloak = new Keycloak(window.ENV.KEYCLOAK_CONFIG);

interface AuthContextValues {
  isAuthenticated: boolean;
  isInitialized: boolean;
  userProfile: any;
  logout: () => void;
  login: () => void;
  getToken: () => Promise<string | undefined>;
  keycloak: Keycloak;
}

const defaultAuthContextValues: AuthContextValues = {
  isAuthenticated: false,
  isInitialized: false,
  userProfile: null,
  logout: () => {},
  login: () => {},
  getToken: async () => {
    throw new Error("getToken function is not implemented");
  },
  keycloak,
};

export const AuthContext = createContext<AuthContextValues>(
  defaultAuthContextValues
);

interface AuthContextProviderProps {
  children: ReactNode;
}

const AuthContextProvider = (props: AuthContextProviderProps) => {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [isInitialized, setInitialized] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<any>(null);
  const { notification, showNotification, closeNotification } = useNotify();

  useEffect(() => {
    async function initializeKeycloak() {
      try {
        const isAuthenticatedResponse = await keycloak.init(
          keycloakInitOptions
        );
        setAuthenticated(isAuthenticatedResponse);
        setInitialized(true);
      } catch {
        showNotification("keycloak initializing error", "error");
        setAuthenticated(false);
        setInitialized(false);
      }
    }
    initializeKeycloak();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function loadProfile() {
      try {
        const profile = await keycloak.loadUserProfile();
        setUserProfile(profile);
      } catch {
        showNotification("error trying to load the users profile", "error");
      }
    }
    if (isAuthenticated) {
      loadProfile();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const logout = () => {
    keycloak.logout({ redirectUri: window.ENV.REDIRECT_URI });
  };

  const login = () => {
    keycloak.login();
  };

  async function getToken() {
    try {
      const refreshed = await keycloak.updateToken(MINVALIDITY);
      if (refreshed) {
        return keycloak.token;
      } else if (keycloak.token) {
        return keycloak.token;
      } else {
        keycloak.login();
      }
    } catch (error) {
      showNotification("Error while refreshing token: " + error, "error");
      keycloak.login();
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userProfile,
        logout,
        login,
        getToken,
        isInitialized,
        keycloak,
      }}
    >
      {props.children}
      {notification ? (
        <Notify
          open={!!notification}
          onClose={closeNotification}
          message={notification.message}
          severity={notification.severity}
        />
      ) : null}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
