import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
//import { useTranslation } from "react-i18next";
import Settings from "./Settings";

import {
  Grid,
  Typography as MuiTypography,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)(spacing);

function Account() {
  //const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title="Account" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h1" gutterBottom>
            Manage Account
          </Typography>
          <Divider my={6} />
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Settings />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Account;
