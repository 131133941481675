import { TypographyOptions } from "@mui/material/styles/createTypography";
import "typeface-hind";

const typography: TypographyOptions = {
  fontFamily: [
    "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontFamily: "Hind",
    fontSize: "64px",
    fontWeight: "bold",
    letterSpacing: "-0.02em",
  },
  h2: {
    fontFamily: "Hind",
    fontSize: "40px",
    fontWeight: "bold",
    letterSpacing: "-0.02em",
  },
  h3: {
    fontFamily: "Hind",
    fontSize: "28px",
    fontWeight: "normal",
    letterSpacing: "-0.02em",
  },
  h4: {
    fontFamily: "Hind",
    fontSize: "24px",
    fontWeight: "bold",
    letterSpacing: "-0.02em",
  },
  h5: {
    fontSize: "1.0625rem",
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h6: {
    fontFamily: "Roboto",
    fontSize: "11px",
    fontWeight: "bold",
  },
  subtitle1: {
    fontFamily: "Hind",
    fontSize: "24px",
    fontWeight: "normal",
  },
  body1: {
    fontFamily: "Roboto",
    fontSize: "16px",
  },
  body2: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
  },
  button: {
    fontFamily: "Roboto",
    fontSize: "11px",
    fontWeight: "bold",
    textTransform: "none",
  },
  overline: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
    textDecoration: "underline",
  },
};

export default typography;
