import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography as MuiTypography,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { spacing } from "@mui/system";

const Typography = styled(MuiTypography)(spacing);

type HomeItemProps = {
  imageSrc: string;
  text: string;
  link: string;
  external?: boolean;
};

function HomeItem(props: HomeItemProps) {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={6}
      lg={4}
      xl={3}
      justifyContent={"center"}
      alignContent="center"
      textAlign={"center"}
    >
      <Card
        sx={{
          padding: "3rem",
          justifyContent: "center",
          height: "20rem",
          maxWidth: "20rem",
          alignItems: "center",
        }}
      >
        <a
          href={props.link}
          target={props.external ? "_blank" : undefined}
          rel="noreferrer"
          style={{ textDecoration: "none", color: "inherit", display: "block" }}
        >
          <CardMedia
            component="img"
            image={props.imageSrc}
            alt={props.text}
            sx={{
              height: "auto",
              width: "auto",
              margin: "auto",
              maxHeight: "150px",
              maxWidth: "150px",
              display: "flex",
            }}
          />

          <CardContent>
            <Typography variant="h5">{props.text}</Typography>
          </CardContent>
        </a>
      </Card>
    </Grid>
  );
}

function Home() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title="Home Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h1" gutterBottom>
            Harbor Console
          </Typography>
          <Typography variant="h3">
            {t("On-demand locker location access with limitless possibilities")}
          </Typography>
        </Grid>
      </Grid>

      <br />
      <Grid container spacing={6} alignItems="center" justifyItems={"center"}>
        <HomeItem
          imageSrc="/static/img/illustrations/documentation.png"
          text="Documentation"
          link="https://docs.harborlockers.com/"
          external={true}
        />
        <HomeItem
          imageSrc="/static/img/illustrations/schedule.png"
          text="Join Weekly Dev Hour - Thursdays at 12PM PT"
          link="https://meet.google.com/qfm-ynow-bpa"
          external={true}
        />
        <HomeItem
          imageSrc="/static/img/illustrations/github.png"
          text="Client Libraries"
          link="https://github.com/orgs/Harbor-Lockers/repositories"
          external={true}
        />
      </Grid>
    </React.Fragment>
  );
}

export default Home;
