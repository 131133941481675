import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PlainTextButton from "../buttons/PlainTextButton";

const AuthFooter = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography variant="body2" color={"white"}>
        {t("copyrightNotice", { year: new Date().getFullYear() })}
      </Typography>
      <Grid container columnSpacing={5} marginTop={1}>
        <Grid item>
          <PlainTextButton
            text={t("securityCookieNotice")}
            to="/auth/sign-in"
            color="white"
          />
        </Grid>
        <Grid item>
          <PlainTextButton
            text={t("globalPrivacyPolicyNotice")}
            to="/auth/sign-in"
            color="white"
          />
        </Grid>
        <Grid item>
          <PlainTextButton
            text={t("termsOfUse")}
            to="/auth/sign-in"
            color="white"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AuthFooter;
