import React from "react";
import { Check } from "@mui/icons-material";
import AccessibleForwardIcon from "@mui/icons-material/AccessibleForward";
import ClearIcon from "@mui/icons-material/Clear";
import AccessibleOutlinedIcon from "@mui/icons-material/AccessibleOutlined";

export const AccessibleIcon = () => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <AccessibleForwardIcon fontSize="medium" style={{ marginRight: "5px" }} />
      <Check fontSize="small" />
    </div>
  );
};

export const NotAccessibleIcon = () => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <AccessibleOutlinedIcon
        fontSize="medium"
        style={{ marginRight: "5px" }}
      />
      <ClearIcon fontSize="small" />
    </div>
  );
};
