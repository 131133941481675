/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Status {
  "AVAILABLE" = 1,
  "OCCUPIED" = 2,
  "RESERVED" = 3,
  "OUT_OF_SERVICE" = 4,
  "RENTED" = 5,
  "DECLINED" = 6,
}

export function getColorByStatus(status: Status): string {
  switch (status) {
    case Status.AVAILABLE:
      return "green";
    case Status.OCCUPIED:
      return "orange";
    case Status.RESERVED:
      return "blue";
    case Status.OUT_OF_SERVICE:
      return "red";
    case Status.RENTED:
      return "DarkViolet";
    case Status.DECLINED:
      return "yellow";
    default:
      return "black";
  }
}
