import React from "react";
import { AlertColor } from "@mui/material";

interface Notification {
  message: string;
  severity: AlertColor;
}

export const useNotify = () => {
  const [notification, setNotification] = React.useState<Notification | null>(
    null
  );

  const showNotification = React.useCallback(
    (message: string, severity: AlertColor) => {
      setNotification({ message, severity });
    },
    []
  );

  const closeNotification = React.useCallback(() => {
    setNotification(null);
  }, []);

  return {
    notification,
    showNotification,
    closeNotification,
  };
};
