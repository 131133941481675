import { Typography, Link as MUILink, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import React from "react";

interface Props {
  text: string;
  to: string;
  color?: string;
  fontWeight?: string | number;
}

const PlainTextButton: React.FC<Props> = ({ text, to, color, fontWeight }) => {
  const theme = useTheme();

  return (
    <Typography variant="body2">
      <MUILink
        component={RouterLink}
        to={to}
        color={color ?? theme.palette.text.primary}
        fontWeight={fontWeight}
      >
        {text}
      </MUILink>
    </Typography>
  );
};

export default PlainTextButton;
