import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";

import { CssBaseline, Grid } from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";
import { ReactComponent as Logo } from "../vendor/logoWhite.svg";
import AuthText from "../components/auth/AuthText";
import AuthFooter from "../components/auth/AuthFooter";
import Settings from "../components/Settings";

const Root = styled.div`
  margin: 0 auto;
  padding-top: 105px;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background-size: cover;
  background-image: url("/static/img/darkAuthBackground.png");
`;

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 243px;
  height: 61px;
  margin-bottom: 32px;
`;

const Auth: React.FC = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Grid container columnSpacing={25} paddingX="100px" maxWidth="1252px">
        <Grid item xs={12}>
          <Brand />
        </Grid>
        <Grid item width={0.5} xs={12} md={6}>
          <AuthText />
        </Grid>
        <Grid item width={0.5} xs={12} md={6}>
          <Outlet />
        </Grid>
        <Grid item>
          <AuthFooter />
        </Grid>
      </Grid>
      <Settings />
    </Root>
  );
};

export default Auth;
