import React from "react";
import { Helmet } from "react-helmet-async";
import { Tower } from "../../base/models/Tower";
import { TowerStatus } from "../../base/models/TowerStatus";
import { TowersService } from "../../base/services/TowersService";
import { ModalEventsReset } from "./modals";
import { setServiceToken } from "../../base/services/base";
import { AuthContext } from "../../contexts/KeycloakContextProvider";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Divider,
  Paper,
  Button,
} from "../../theme/styledComponents";
import Notify from "../Notify";
import { useNotify } from "../../hooks/useNotify";
import Loader from "../Loader";
import EmptyTowersCard from "./emptyTowersCard";

function TowerTable({ towers }: { towers: Tower[] }) {
  const { getToken } = React.useContext(AuthContext);
  const [openResetModal, setOpenResetModal] = React.useState(false);
  const [openReleaseModal, setOpenReleaseModal] = React.useState(false);
  const [selectedTowerId, setSelectedTowerId] = React.useState("");
  const { notification, showNotification, closeNotification } = useNotify();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleResetModalOpen = (towerId: string) => {
    setSelectedTowerId(towerId);
    setOpenResetModal(true);
  };

  const handleReleaseModalOpen = (towerId: string) => {
    setSelectedTowerId(towerId);
    setOpenReleaseModal(true);
  };

  const handleModalClose = () => {
    setOpenResetModal(false);
    setOpenReleaseModal(false);
  };

  const handleResetConfirm = () => {
    resetTowerEvents(selectedTowerId);
    setOpenResetModal(false);
  };
  const handleReleaseConfirm = () => {
    releaseLockers(selectedTowerId);
    setOpenReleaseModal(false);
  };

  const resetTowerEvents = async (towerId: string) => {
    setIsLoading(true);
    try {
      const token = await getToken();
      setServiceToken(TowersService, token)();
      await TowersService.resetTowerEventsApiV1TowersTowerIdResetEventsPost(
        towerId
      );
      setIsLoading(false);
      showNotification("Tower events successfully reset", "success");
    } catch (error) {
      setIsLoading(false);
      showNotification(`Error resetting tower events: ${error}`, "error");
    }
  };
  const releaseLockers = async (towerId: string) => {
    setIsLoading(true);
    try {
      const token = await getToken();
      setServiceToken(TowersService, token)();
      await TowersService.setLockersToAvailableApiV1TowersTowerIdReleaseLockersPost(
        towerId
      );
      setIsLoading(false);
      showNotification("Lockers released successfully!", "success");
    } catch (error) {
      setIsLoading(false);
      showNotification(`Error releasing lockers: ${error}`, "error");
    }
  };

  const navigate = useNavigate();
  return (
    <Card mb={6}>
      {isLoading && <Loader />}
      <CardContent pb={1}>
        <Typography variant="h6" gutterBottom>
          Towers
        </Typography>
      </CardContent>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">System</TableCell>
              <TableCell align="left">Location</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Total Lockers</TableCell>
              <TableCell align="left">Reset Tower</TableCell>
              <TableCell align="left">Release Lockers</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {towers.length === 0 ? (
              <EmptyTowersCard />
            ) : (
              towers.map((tower) => (
                <TableRow key={tower.id}>
                  <TableCell component="th" scope="row">
                    {tower.id}
                  </TableCell>
                  <TableCell align="left">{tower.name}</TableCell>
                  <TableCell align="left">{tower.systemId}</TableCell>
                  <TableCell align="left">{tower.locationId}</TableCell>
                  <TableCell align="left">
                    {TowerStatus[tower.statusId]}
                  </TableCell>
                  <TableCell align="left">{tower.lockers.length}</TableCell>
                  <TableCell align="left">
                    <Button
                      variant="contained"
                      onClick={() => handleResetModalOpen(tower.id)}
                    >
                      Reset Tower Events
                    </Button>
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      variant="contained"
                      onClick={() => handleReleaseModalOpen(tower.id)}
                    >
                      Release Lockers
                    </Button>
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      variant="contained"
                      onClick={() => navigate(`${tower.id}`)}
                    >
                      View <ArrowRightAlt />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Paper>
      <ModalEventsReset
        open={openResetModal}
        onClose={handleModalClose}
        onConfirmReset={handleResetConfirm}
        towerId={selectedTowerId}
        actionType="resetEvents"
      />
      <ModalEventsReset
        open={openReleaseModal}
        onClose={handleModalClose}
        onConfirmReset={handleReleaseConfirm}
        towerId={selectedTowerId}
        actionType="releaseLockers"
      />
      {notification ? (
        <Notify
          open={!!notification}
          onClose={closeNotification}
          message={notification.message}
          severity={notification.severity}
        />
      ) : null}
    </Card>
  );
}

function AdvancedTowerTable({ towers }: { towers: Tower[] }) {
  return (
    <React.Fragment>
      <Helmet title="Towers" />
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <TowerTable towers={towers} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AdvancedTowerTable;
