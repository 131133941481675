import React from "react";
import styled from "styled-components/macro";
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import useAuth from "../../hooks/useAuth";

import { spacing, SpacingProps } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

function Settings() {
  const { user } = useAuth();

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h3" gutterBottom>
          Settings
        </Typography>

        <Grid container spacing={6}>
          <Grid item md={6}>
            <TextField
              id="first-name"
              label="First name"
              variant="outlined"
              value={user !== null ? user.firstName : ""}
              fullWidth
              my={2}
            />
          </Grid>

          <Grid item md={6}>
            <TextField
              id="last-name"
              label="Last name"
              variant="outlined"
              value={user !== null ? user.lastName : ""}
              fullWidth
              my={2}
            />
          </Grid>

          <Grid item md={6}>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              value={user !== null ? user.email : ""}
              fullWidth
              my={2}
            />
          </Grid>
        </Grid>

        <Button
          variant="contained"
          color="primary"
          mt={3}
          size="large"
          disabled
        >
          Update Settings
        </Button>
      </CardContent>
    </Card>
  );
}

export default Settings;
