import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Button as MuiButton,
  Paper as MuiPaper,
  TextField as MuiTextField,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Alert as MuiAlert,
} from "@mui/material";

import { spacing, SpacingProps } from "@mui/system";
import styled from "styled-components/macro";
import { tableCellClasses } from "@mui/material/TableCell";

export const Divider = styled(MuiDivider)(spacing);

export const Card = styled(MuiCard)(spacing);

export const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

export const CardContent = styled(MuiCardContent)(spacing);
export const Button = styled(MuiButton)<ButtonProps>(spacing);
export const Alert = styled(MuiAlert)(spacing);
export const Paper = styled(MuiPaper)(spacing);

export const TableCell = styled(MuiTableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

export const TableRow = styled(MuiTableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;
