import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "../theme/styledComponents";
import { AlertColor } from "@mui/material";

interface NotificationProps {
  open: boolean;
  onClose: () => void;
  message: string;
  severity: AlertColor;
}

const Notify: React.FC<NotificationProps> = ({
  open,
  onClose,
  message,
  severity,
}) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <Alert mb={4} variant="filled" severity={severity}>
        {" "}
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notify;
