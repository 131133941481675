import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/KeycloakContextProvider";
import { Tower } from "../../base/models/Tower";
import Loader from "../../components/Loader";
import { setServiceToken } from "../../base/services/base";
import { TowersService } from "../../base/services/TowersService";
import { Helmet } from "react-helmet-async";
import { Details } from "../../components/towers/towerDetails";
import { Grid, Typography } from "@mui/material";
import { Divider } from "../../theme/styledComponents";

function TowerDetails() {
  const navigate = useNavigate();
  const { towerId } = useParams();
  const [tower, setTower] = React.useState<Tower | null>();

  const { isInitialized, isAuthenticated, getToken } =
    React.useContext(AuthContext);
  React.useEffect(() => {
    if (isInitialized && isAuthenticated) {
      const fetchData = async () => {
        try {
          const token = await getToken();
          setServiceToken(TowersService, token)();
          const tower = await TowersService.getTowerApiV1TowersTowerIdGet(
            towerId!
          );
          const lockers =
            await TowersService.getLockersApiV1TowersTowerIdLockersGet(
              towerId!
            );
          const towerWithLockers = { ...tower, lockers };
          setTower(towerWithLockers);
        } catch (error: any) {
          console.error("Error fetching tower data:", error.status);
          if (error.status === 404) {
            navigate("/404");
          }
        }
      };
      fetchData();
    }
  }, [isAuthenticated, isInitialized, towerId, getToken, navigate]);
  if (!tower) {
    return Loader();
  }

  return (
    <React.Fragment>
      <Helmet title="Tower" />
      <Grid justifyContent="space-between">
        <Grid item>
          <Typography variant="h1" gutterBottom>
            Tower Details
          </Typography>
          <Helmet title="Tower Details" />
          <Divider my={6} />
          <Details tower={tower} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TowerDetails;
