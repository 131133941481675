import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Alert, Button } from "../../theme/styledComponents";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onConfirmReset: () => void;
  actionType: "resetEvents" | "releaseLockers";
  towerId: string;
}

export function ModalEventsReset(props: ModalProps) {
  const { onClose, open, towerId, onConfirmReset, actionType } = props;

  const getTitleAndDescription = () => {
    switch (actionType) {
      case "resetEvents":
        return {
          title: "Reset Events?",
          description: `Are you sure you want to reset events for tower "${towerId}"?`,
          button: "Reset",
        };
      case "releaseLockers":
        return {
          title: "Release Lockers?",
          description: `Are you sure you want to release lockers for tower "${towerId}"?`,
          button: "Release",
        };
      default:
        return {
          title: "Confirm Action",
          description: "Are you sure you want to perform this action?",
          button: "Button Title",
        };
    }
  };

  const { title, description, button } = getTitleAndDescription();

  const handleClose = () => {
    onClose();
  };
  const handleConfirm = () => {
    onConfirmReset();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-describedby="alert-dialog-description"
    >
      <Alert mb={4} severity="warning">
        {title}
      </Alert>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="info" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          autoFocus
          variant="contained"
        >
          {button}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
