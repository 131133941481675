import { SidebarItemsType } from "../../types/sidebar";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ForumIcon from "@mui/icons-material/Forum";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import {
  Layout,
  ShoppingCart,
  Maximize,
  Home,
  HelpCircle,
} from "react-feather";

const pagesSection = [
  {
    href: "/",
    icon: Home,
    title: "Home",
  },

  {
    href: "/",
    icon: Maximize,
    title: "Locker Configuration",
    children: [
      {
        href: "towers",
        title: "Towers",
      },
    ],
  },
] as SidebarItemsType[];

const externalSection = [
  {
    href: "https://docs.harborlockers.com/",
    icon: Layout,
    title: "Documentation",
    external: true,
  },
  {
    href: "https://store.harborlockers.com/",
    icon: ShoppingCart,
    title: "Store",
    external: true,
  },
  {
    href: "https://harborlockers.com/changelog/",
    icon: PublishedWithChangesIcon,
    title: "Changelogs",
    external: true,
  },
  {
    href: "https://community.harborlockers.com/",
    icon: ForumIcon,
    title: "Forums",
    external: true,
  },

  {
    href: "https://harborlockers.com/submit-a-ticket/",
    icon: HelpCircle,
    title: "Support Ticket",
    external: true,
  },
  {
    href: "https://status.harborlockers.com/",
    icon: AnalyticsIcon,
    title: "Platform Status",
    external: true,
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "",
    pages: pagesSection,
  },
  {
    title: "external links",
    pages: externalSection,
  },
];

export default navItems;
