import * as React from "react";
import { Card, CardContent, Typography, Divider, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import InboxIcon from "@mui/icons-material/Inbox";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "400px",
    borderTop: "5px solid yellow",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    fontSize: "3rem",
    marginTop: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
}));

const EmptyTowersCard = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography variant="h5" className={classes.title}>
          No Towers
        </Typography>
        <Divider />
        <Typography variant="body1" align="center" gutterBottom>
          Hmm, looks like you have not any tower yet...
        </Typography>
        <InboxIcon className={classes.icon} />
      </CardContent>
    </Card>
  );
};

export default EmptyTowersCard;
