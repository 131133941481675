import React from "react";
import { Locker } from "../../base/models/Locker";
import { TowersService } from "../../base/services/TowersService";
import { ModalEventsReset } from "./modals";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  Typography,
  SelectChangeEvent,
  MenuItem,
  Select,
} from "@mui/material";
import { AccessibleIcon, NotAccessibleIcon } from "./accessibleIcons";
import RefreshIcon from "@mui/icons-material/Refresh";
import CachedIcon from "@mui/icons-material/Cached";
import { Tower } from "../../base/models/Tower";
import { Status, getColorByStatus } from "../../base/models/Status";
import { setServiceToken } from "../../base/services/base";
import { AuthContext } from "../../contexts/KeycloakContextProvider";
import Notify from "../Notify";
import { useNotify } from "../../hooks/useNotify";
import Loader from "../Loader";
import {
  Card,
  CardContent,
  Button,
  Paper,
  TableCell,
  TableRow,
} from "../../theme/styledComponents";

export function LockersTable({
  tower,
  lockers,
}: {
  tower: Tower;
  lockers: Locker[];
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const { getToken } = React.useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [refreshedLockers, setRefreshedLockers] = React.useState(lockers);
  const { notification, showNotification, closeNotification } = useNotify();

  const refreshLockers = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const token = await getToken();
      setServiceToken(TowersService, token)();
      const refreshedLockersResponse =
        await TowersService.getLockersApiV1TowersTowerIdLockersGet(tower.id);
      setRefreshedLockers(refreshedLockersResponse);
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      showNotification("Error refreshing lockers: " + error, "error");
      return false;
    }
  }, [tower.id, getToken, setRefreshedLockers, showNotification]);

  const handleRefreshLockers = async () => {
    const refreshSuccess = await refreshLockers();
    if (refreshSuccess) {
      showNotification("Locker status updated successfully!", "success");
    }
  };

  React.useEffect(() => {
    window.addEventListener("focus", refreshLockers);
    return () => {
      window.removeEventListener("focus", refreshLockers);
    };
  }, [refreshLockers]);

  const resetTowerEvents = async (towerId: string) => {
    setIsLoading(true);
    try {
      await TowersService.resetTowerEventsApiV1TowersTowerIdResetEventsPost(
        towerId
      );
      setIsLoading(false);
      showNotification("Tower successfully reset", "success");
    } catch (error) {
      setIsLoading(false);
      showNotification("Error resetting tower events: " + error, "error");
    }
  };

  const handleResetButtonClick = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleConfirmReset = () => {
    resetTowerEvents(tower.id);
    setOpen(false);
  };
  return (
    <Card mb={6}>
      {isLoading && <Loader />}
      <CardContent pb={1}>
        <Typography variant="h6" gutterBottom>
          Lockers
        </Typography>
        <Typography variant="body2" gutterBottom>
          A list of lockers in this tower
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <Button mr={2} variant="contained" onClick={handleResetButtonClick}>
            <RefreshIcon />
            Reset Events
          </Button>
          <Button mr={2} variant="contained" onClick={handleRefreshLockers}>
            <CachedIcon />
            Refresh Locker Status
          </Button>
        </Box>
      </CardContent>
      <GetLockerTable refreshedLockers={refreshedLockers} />
      <ModalEventsReset
        open={open}
        onClose={handleModalClose}
        onConfirmReset={handleConfirmReset}
        towerId={tower.id}
        actionType="resetEvents"
      />
      {notification ? (
        <Notify
          open={!!notification}
          onClose={closeNotification}
          message={notification.message}
          severity={notification.severity}
        />
      ) : null}
    </Card>
  );
}

type Props = {
  refreshedLockers: Locker[];
};

const GetLockerTable: React.FC<Props> = ({ refreshedLockers }) => {
  const { getToken } = React.useContext(AuthContext);
  const [selectedStatuses, setSelectedStatuses] = React.useState<{
    [lockerId: number]: Status;
  }>({});
  const { notification, showNotification, closeNotification } = useNotify();

  const handleStatusChange = async (
    event: SelectChangeEvent<number>,
    lockerId: number
  ) => {
    const newStatus = event.target.value as Status;
    try {
      const token = await getToken();
      setServiceToken(TowersService, token)();
      await TowersService.updateLockerStatusApiV1TowersTowerIdLockersLockerIdPut(
        lockerId,
        newStatus
      );
      setSelectedStatuses((prevSelectedStatuses) => ({
        ...prevSelectedStatuses,
        [lockerId]: newStatus,
      }));
      showNotification("Locker status updated successfully!", "success");
    } catch (error) {
      showNotification(`Error updating locker status: ${error}`, "error");
    }
  };
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Accessible</TableCell>
            <TableCell align="left">Port</TableCell>
            <TableCell align="left">Size</TableCell>
            <TableCell align="left">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {refreshedLockers
            .sort((a, b) => a.id - b.id)
            .map((locker) => (
              <TableRow key={locker.id}>
                <TableCell component="th" scope="row">
                  {locker.id}
                </TableCell>
                <TableCell align="left">{locker.name}</TableCell>
                <TableCell align="left">
                  {locker.isLowLocker ? (
                    <AccessibleIcon />
                  ) : (
                    <NotAccessibleIcon />
                  )}
                </TableCell>
                <TableCell align="left">{locker.physicalId}</TableCell>
                <TableCell align="left">{locker.type.name}</TableCell>
                <TableCell align="left">
                  <Select
                    value={selectedStatuses[locker.id] || locker.statusId}
                    onChange={(event) => handleStatusChange(event, locker.id)}
                    style={{
                      border: `1px solid ${getColorByStatus(
                        selectedStatuses[locker.id] || locker.statusId
                      )}`,
                    }}
                  >
                    <MenuItem value={Status.AVAILABLE}>Available</MenuItem>
                    <MenuItem value={Status.OCCUPIED}>Occupied</MenuItem>
                    <MenuItem value={Status.RESERVED}>Reserved</MenuItem>
                    <MenuItem value={Status.OUT_OF_SERVICE}>
                      Out of service
                    </MenuItem>
                    <MenuItem value={Status.RENTED}>Rented</MenuItem>
                    <MenuItem value={Status.DECLINED}>Declined</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {notification ? (
        <Notify
          open={!!notification}
          onClose={closeNotification}
          message={notification.message}
          severity={notification.severity}
        />
      ) : null}
    </Paper>
  );
};
