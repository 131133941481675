import React from "react";

// Layouts
import AuthLayout from "./layouts/Auth";
import HalDashboardLayout from "./layouts/HalDashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
// Auth components
import Page404 from "./pages/auth/Page404";

//Harbor Pages
// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";
import EventLogs from "./pages/events";
import Towers from "./pages/towers";
import MyLocations from "./pages/locations";
import MySystems from "./pages/systems";
import DevBoard from "./pages/dev_board";
import Account from "./pages/account";
import TowerDetails from "./pages/towers/details";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <HalDashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
      {
        path: "events",
        element: <EventLogs />,
      },
      {
        path: "locations",
        element: <MyLocations />,
      },
      {
        path: "towers",
        children: [
          {
            path: "",
            element: <Towers />,
          },
          {
            path: ":towerId",
            element: <TowerDetails />,
          },
        ],
      },

      {
        path: "systems",
        element: <MySystems />,
      },
      {
        path: "dev-board",
        element: <DevBoard />,
      },
      {
        path: "account",
        element: <Account />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
