import React from "react";
import Home from "../dashboards/Home";

function ProtectedPage() {
  return (
    <React.Fragment>
      <Home />
    </React.Fragment>
  );
}

export default ProtectedPage;
