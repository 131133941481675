import * as React from "react";
import useAuth from "../../hooks/useAuth";
import Loader from "../Loader";

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const { isAuthenticated, isInitialized, signIn } = useAuth();
  if (!isInitialized) {
    return <Loader />;
  }
  if (isInitialized && !isAuthenticated) {
    signIn();
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
