import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Divider,
  Grid,
} from "@mui/material";
import { Helmet } from "react-helmet-async";

const TableLoader: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet title="Towers" />
      <Divider sx={{ my: 6 }} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ mb: 6 }}>
            <CardContent sx={{ pb: 1 }}>
              <Typography variant="h6">
                <Skeleton animation="wave" />
              </Typography>
            </CardContent>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    {Array(8)
                      .fill(null)
                      .map((_, idx) => (
                        <TableCell key={idx}>
                          <Skeleton animation="wave" />
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array(5)
                    .fill(null)
                    .map((_, rowIdx) => (
                      <TableRow key={rowIdx}>
                        {Array(8)
                          .fill(null)
                          .map((_, cellIdx) => (
                            <TableCell key={cellIdx}>
                              <Skeleton animation="wave" />
                            </TableCell>
                          ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default TableLoader;
