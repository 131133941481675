import React from "react";
import { Tower } from "../../base/models/Tower";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Grid, Typography as MuiTypography } from "@mui/material";
import { spacing } from "@mui/system";
import AdvancedTowerTable from "../../components/towers/towersList";
import { AuthContext } from "../../contexts/KeycloakContextProvider";
import { TowersService } from "../../base/services/TowersService";
import { setServiceToken } from "../../base/services/base";
import Notify from "../../components/Notify";
import { useNotify } from "../../hooks/useNotify";
import TableLoader from "../../components/TableLoader";
const Typography = styled(MuiTypography)(spacing);

function Towers() {
  const { t } = useTranslation();
  const [towers, setTowers] = React.useState<Tower[]>([]);
  const { isAuthenticated, isInitialized, getToken } =
    React.useContext(AuthContext);
  const { notification, showNotification, closeNotification } = useNotify();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (isAuthenticated && isInitialized) {
      (async () => {
        try {
          const token = await getToken();
          setServiceToken(TowersService, token)();
          const towers = await TowersService.getAllTowersApiV1TowersGet();
          const updatedTowersPromises = towers.map(async (tower) => {
            const lockers =
              await TowersService.getLockersApiV1TowersTowerIdLockersGet(
                tower.id
              );
            return { ...tower, lockers };
          });
          const updatedTowers = await Promise.all(updatedTowersPromises);
          setTowers(updatedTowers);
          setIsLoading(false);
        } catch (error) {
          showNotification("Error while refreshing token: " + error, "error");
        }
      })();
    }
  }, [isAuthenticated, isInitialized, getToken, showNotification]);

  return (
    <React.Fragment>
      <Helmet title="My Towers" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h1" gutterBottom>
            My Towers
          </Typography>
          <Typography variant="h3">
            {t(
              "Towers are a group of lockers with a control board with locks attached."
            )}
          </Typography>
        </Grid>
        {isLoading ? <TableLoader /> : <AdvancedTowerTable towers={towers} />}
        {notification ? (
          <Notify
            open={!!notification}
            onClose={closeNotification}
            message={notification.message}
            severity={notification.severity}
          />
        ) : null}
      </Grid>
    </React.Fragment>
  );
}

export default Towers;
