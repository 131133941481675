const spanish = {
  translation: {
    // Auth Screen
    HarborConnect: "HARBOR CONNECT",
    WelcomeMessageTitle:
      "Bienvenido a la red de Lockers más avanzada del mundo",
    WelcomeMessageDescription:
      "La única red pública de Lockers confiada por desarrolladores y organizaciones de todos los tamaños para enviar, almacenar y recibir artículos con posibilidades infinitas.",

    // Signup
    signUpTitle: "Registrarse para Acceder",
    signUpBody: "Regístrate para acceder a la plataforma de Harbor Lockers.",
    signUpButtonTitle: "Registrarse",
    byRequestingAccess: "Al pedir acceso, adhieres a nuestros",
    haveAnAccount: "Tienes una cuenta? ",

    // Sign in
    loginTitle: "Inicia sesión en tu cuenta",
    dontHaveAnAccount: "No tienes una cuenta? ",
    loginButtonTitle: "Iniciar sesión",
    forgotPasswordButtonTitle: "Olvidaste tu contraseña?",
    staySignedIn: "Recordar mi sesión por una semana",

    // Reset Password
    resetPasswordTitle: "Restablecer Contraseña",
    resetPasswordBody: "Ingresa tu email para restablecer tu contraseña",
    resetPasswordButtonTitle: "Restablecer contraseña",

    // Forms
    email: "Email",
    fullName: "Nombre Completo",
    company: "Compañía",
    phone: "Teléfono",
    password: "Contraseña",
    confirmPassword: "Confirmar contraseña",

    // Form errors
    emailRequired: "Email es requerido",
    emailInvalid: "La dirección debe ser válida",
    fullNameRequired: "Nombre completo es requerido",
    companyRequired: "Nombre de la compañía es requerido",
    phoneRequired: "Teléfono es requerido",
    passwordRequired: "Contraseña es requerida",
    passwordMinLength: "La contraseña debe tener al menos 12 caracteres",
    passwordMismatch: "Ambas contraseñas deben coincidir",

    // Footer Links
    CopyrightNotice: "Copyright © {{year}}. Todos los derechos reservados.",
    SecurityCookieNotice: "Aviso de Seguridad de Cookies",
    GlobalPrivacyPolicyNotice: "Política de Privacidad",
    TermsOfUse: "Términos de Uso",
    termsOfService: "Terms of Service",

    //Might need to remove these
    Search: "Buscar…",
    "Welcome back": "Bienvenido",
    "We've missed you": "Te extrañamos",
  },
};

export default spanish;
