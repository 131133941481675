import { useContext } from "react";
import { AuthContext } from "../contexts/KeycloakContextProvider";

const useAuth = () => {
  const { isAuthenticated, isInitialized, userProfile, login, logout } =
    useContext(AuthContext);

  //the SignUp component expects to receive a signup method but it is not implemented yet
  async function signUp(
    email: string,
    password: string,
    fullName: string,
    company: string,
    phone: string
  ) {
    return Promise.resolve();
  }

  const user = userProfile;
  const signIn = login;
  const signOut = logout;
  return {
    isAuthenticated: isAuthenticated,
    isInitialized,
    signOut,
    signIn,
    signUp,
    user,
  };
};

export default useAuth;
