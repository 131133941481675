import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishTranslation from "./en";
import spanishTranslation from "./es";

const resources = {
  en: englishTranslation,
  es: spanishTranslation,
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
