const english = {
  translation: {
    // Auth Screen
    harborConnect: "HARBOR CONNECT",
    welcomeMessageTitle: "Welcome to the world's most advanced locker network",
    welcomeMessageDescription:
      "The only public locker network trusted by developers and organizations of all sizes to easily ship, store, and receive goods with infinite possibilities.",

    // Signup
    signUpTitle: "Sign up for access",
    signUpBody: "Sign up to access Harbor Lockers platform",
    signUpButtonTitle: "Sign Up",
    byRequestingAccess: "By requesting access you agree to our",
    haveAnAccount: "Have an account? ",

    // Sign in
    loginTitle: "Login to your account",
    dontHaveAnAccount: "Don't have an account? ",
    loginButtonTitle: "Login",
    forgotPasswordButtonTitle: "Forgot password?",
    staySignedIn: "Stay signed in for a week",

    // forgot Password
    forgotPasswordTitle: "Forgot your password?",
    forgotPasswordBody:
      "Ensure your email is typed correctly and we will send you an email with instructions  to reset it",
    resetPasswordButtonTitle: "Reset password",
    resetPasswordTitle: "Enter Your New Password",
    restAndLoginButtonTitle: "Reset and Log in",
    awaitToResendButtonTitle: "Await to resend",

    // Forms
    email: "Email",
    fullName: "Full Name",
    company: "Company",
    phone: "Phone",
    password: "Password",
    confirmPassword: "Confirm password",

    // Form errors
    emailRequired: "Email is required",
    emailInvalid: "Email must be a valid email",
    fullNameRequired: "Full name is required",
    companyRequired: "Company name is required",
    phoneRequired: "Phone is required",
    passwordRequired: "Password is required",
    passwordMinLength: "Password must be at least 12 characters",
    passwordMismatch: "Both password need to be the same",

    // Footer Links
    copyrightNotice: "Copyright © {{year}}. All rights reserved.",
    securityCookieNotice: "Security Cookie Notice",
    globalPrivacyPolicyNotice: "Global Privacy Privacy Notice",
    termsOfUse: "Terms of Use",
    termsOfService: "Terms of Service",

    //Might need to remove these
    Search: "Search topics…",
    "Welcome back": "Welcome back",
    "We've missed you": "We've missed you",
  },
};

export default english;
